import React, { useState, useEffect } from 'react';
import { db } from '../../services/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Client, FetchedClient } from '../../types/types';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';  // <-- Import Box

import styles from "../../App.module.scss";

interface ClientListProps {
    agencyId?: string;
}

const ClientList: React.FC<ClientListProps> = ({ agencyId }) => {
    const [clients, setClients] = useState<FetchedClient[]>([]);
    const [taskCounts, setTaskCounts] = useState<{ [clientId: string]: number }>({});
    const [projectCounts, setProjectCounts] = useState<{ [clientId: string]: number }>({});

    useEffect(() => {
        const fetchClients = async () => {
            try {
                console.log('Fetching clients...');
                const clientsCol = collection(db, "clients");
                const clientsQuery = agencyId ? query(clientsCol, where('agencyId', '==', agencyId)) : clientsCol;
                const clientsSnapshot = await getDocs(clientsQuery);

                const fetchedClients = clientsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...(doc.data() as Client)
                }));

                console.log(`Fetched ${fetchedClients.length} clients:`, fetchedClients);

                const initialTaskCounts: { [clientId: string]: number } = {};
                const initialProjectCounts: { [clientId: string]: number } = {};
                fetchedClients.forEach(client => {
                    initialTaskCounts[client.id] = 0;
                    initialProjectCounts[client.id] = 0;
                });

                for (let client of fetchedClients) {
                    console.log(`Fetching tasks for client ${client.id}...`);
                    const tasksCol = collection(db, "tasks");
                    const tasksQuery = query(tasksCol, where("clientId", "==", client.id));
                    const tasksSnapshot = await getDocs(tasksQuery);
                    initialTaskCounts[client.id] = tasksSnapshot.docs.length;

                    console.log(`Fetching projects for client ${client.id}...`);
                    const projectsCol = collection(db, "projects");
                    const projectsQuery = query(projectsCol, where("clientId", "==", client.id));
                    const projectsSnapshot = await getDocs(projectsQuery);
                    initialProjectCounts[client.id] = projectsSnapshot.docs.length;
                }

                setClients(fetchedClients);
                setTaskCounts(initialTaskCounts);
                setProjectCounts(initialProjectCounts);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchClients();
    }, [agencyId]);

    return (
        <Box padding={2}>  {/* Use Box as an outer wrapper */}
            <p>Client List</p>
            {clients.map(client => (
                <Link to={`/clients/${client.id}`} key={client.id}>
                    <Box marginY={1} component="div" className={styles.card}>  {/* Add vertical margin */}
                        <Box padding={1} component="div" className={styles.top}>  {/* Add padding */}
                            <h3>{client.name}</h3>
                        </Box>
                        <Box padding={1} component="div" className={styles.bottom}>  {/* Add padding */}
                            <p>Projects: {projectCounts[client.id] || 0}</p>
                            <p>Tasks: {taskCounts[client.id] || 0}</p>
                            <p><i>{client.description}</i></p>
                        </Box>
                    </Box>
                </Link>
            ))}
        </Box>
    );
};

export default ClientList;
