// Firebase imports
import { initializeApp } from "firebase/app";
import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCIRZf7ES6u52HxUnmvVXipSSspGSSrPwI",
    authDomain: "timetracker-b1dd8.firebaseapp.com",
    projectId: "timetracker-b1dd8",
    storageBucket: "timetracker-b1dd8.appspot.com",
    messagingSenderId: "1075086770148",
    appId: "1:1075086770148:web:46807ba1ae14141ab5d1f1"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Firestore instance
const db = getFirestore(app);

// Enable offline persistence for Firestore
enableIndexedDbPersistence(db)
    .catch((err) => {
        if (err.code === 'failed-precondition') {
            console.error("Multiple tabs open, persistence can only be enabled in one tab at a time.");
        } else if (err.code === 'unimplemented') {
            console.error("The current browser does not support all of the features required to enable persistence.");
        }
    });

// Firebase Authentication instance
const auth = getAuth(app);

// Exports
export { db, auth };
