// newTask.tsx
import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Input,
    Box,
    Grid,
    CircularProgress,
    ThemeProvider
} from '@mui/material';
import { db, auth } from '../../services/firebase';
import { collection, addDoc, getDocs, Timestamp } from 'firebase/firestore';
import { Client, Task } from '../../types/types';
import styles from '../../App.module.scss';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customTheme from "../../themes/theme";
import MarkdownEditor from '../layout/MarkdownEditor';

export type TaskStatus = "billable" | "unbillable" | "invoiced";


interface NewTaskProps {
    id?: string;
    clientId?: string;
    projectId?: string;
    date?: Date | null;
    taskStatus: TaskStatus;
    elapsedTime?: number; // in seconds
}

interface MarkdownEditorProps {
    markdown: string;
    onChange: (value: string) => void;
}



const NewTask: React.FC<NewTaskProps> = ({ clientId, projectId, date }) => {
    const [title, setTitle] = useState<string>("");
    const [client, setClient] = useState<string>(clientId || "");
    const [clients, setClients] = useState<Client[]>([]);
    const [description, setDescription] = useState<string>("");
    const [startDate, setStartDate] = useState<Date | null>(date || null);
    const [projects, setProjects] = useState<string[]>([]);
    const [selectedProject, setSelectedProject] = useState<string>(projectId || "");
    const [taskStatus, setTaskStatus] = useState<TaskStatus>("billable");
    const [timerStarted, setTimerStarted] = useState<Date | null>(null);
    // const [elapsedTime, setElapsedTime] = useState<number | null>(null);
    const [minutes, setMinutes] = useState<number>(0);
    const [timer, setTimer] = useState<number>(0);
    const [intervalId, setIntervalId] = useState<number | null>(null);


    useEffect(() => {
        const fetchClientsAndProjects = async () => {
            try {
                const clientsCol = collection(db, "clients");
                const clientSnapshot = await getDocs(clientsCol);
                const clientList: Client[] = clientSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data() as Client
                }));

                const projectsCol = collection(db, "projects");
                const projectSnapshot = await getDocs(projectsCol);
                const projectList = projectSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data() as any // or specify the exact type if you have it
                }));

                console.log("Clients:", clientList);
                console.log("Projects:", projectList);

                setProjects(projectList);
                setClients(clientList);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };


        fetchClientsAndProjects();
    }, []);

    // Timer Persistence: Store and retrieve startTime from local storage
    useEffect(() => {
        const storedStartTime = localStorage.getItem('startTime');

        const storedTitle = localStorage.getItem('taskTitle');
        const storedDescription = localStorage.getItem('taskDescription');

        if (storedStartTime) {
            setTimerStarted(new Date(storedStartTime));
        }
        if (storedTitle) setTitle(storedTitle);
        if (storedDescription) setDescription(storedDescription);
    }, []);

    const saveFormData = () => {
        localStorage.setItem('taskTitle', title);
        localStorage.setItem('taskDescription', description);
        // ... save other fields
    };


    useEffect(() => {
        if (timerStarted) {
            localStorage.setItem('startTime', timerStarted.toString());
        } else {
            localStorage.removeItem('startTime');
        }
    }, [timerStarted]);

    useEffect(() => {
        saveFormData();
    }, [title, description /* ... other states */]);


    const handleStart = () => {
        setTimerStarted(new Date());
        console.log("Start button clicked");

        const id = window.setInterval(() => {
            setMinutes(prevMinutes => prevMinutes + 1);
            console.log("Minutes:", minutes);

        }, 60000);  // Update every minute

        setIntervalId(id);
    };



    const handleStop = () => {
        const now = new Date();
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }

        // Clear the interval
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }

        // Clearing the timerStarted from local storage on stopping the timer
        setTimerStarted(null);
    };


    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [intervalId]);


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const currentUser = auth.currentUser;
        if (!currentUser) {
            console.error("User not authenticated");
            return;
        }

        let finalElapsedTimeInMinutes;

// Check if manually entered time is present and use it
        if (timer > 0) {
            finalElapsedTimeInMinutes = timer;
        } else {
            finalElapsedTimeInMinutes = minutes;
        }

        const task: Task = {
            title,
            taskStatus,
            status: taskStatus,
            clientId: client,
            description,
            date: Timestamp.fromDate(startDate || new Date()),
            userId: currentUser.uid,
            projectId: selectedProject || undefined,
            elapsedTime: finalElapsedTimeInMinutes || null
        };

        try {
            await addDoc(collection(db, "tasks"), task);

            // Clear form fields upon successful submission
            setTitle("");
            setClient(clientId || "");
            setDescription("");
            setStartDate(date || null);
            setSelectedProject(projectId || "");
            setTimer(0); // Clear the manual timer

// Also clear stored data from localStorage
            localStorage.removeItem('taskTitle');
            localStorage.removeItem('taskDescription');


        } catch (error) {
            console.error("Error adding task:", error);
        }
    };

    const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newMinutes = Number(e.target.value);
        setMinutes(newMinutes);
        setTimer(newMinutes);  // Store directly in minutes
    };


    const isSubmitDisabled = !selectedProject;




    return (
        <div className={styles.card}>
            <form onSubmit={handleSubmit}>
                <Box marginBottom={2}>
                    <TextField fullWidth label="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                </Box>

                <Box marginBottom={2}>
                    <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={taskStatus}
                            onChange={(e) => setTaskStatus(e.target.value as TaskStatus)}
                        >
                            <MenuItem value={"billable"}>Billable</MenuItem>
                            <MenuItem value={"unbillable"}>Unbillable</MenuItem>
                            <MenuItem value={"invoiced"}>Invoiced</MenuItem>
                        </Select>
                    </FormControl>
                </Box>


                {/*select a project if one isnt supplied by default*/}
                {!projectId && (
                    <Box marginBottom={2}>
                        <FormControl fullWidth>
                            <InputLabel>Project</InputLabel>
                            <Select value={selectedProject} onChange={(e) => setSelectedProject(e.target.value as string)}>
                                {projects.map((project: any) => {
                                    const associatedClient = clients.find(c => c.id === project.clientId);
                                    return (
                                        <MenuItem key={project.id} value={project.title}>
                                            {associatedClient ? `${associatedClient.name} - ${project.title}` : project.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>

                        </FormControl>
                    </Box>
                )}

                {/*description*/}
                <Box marginBottom={2}>
                    <MarkdownEditor
                        markdown={description}
                        onChange={(newDescription) => setDescription(newDescription)}
                        isEditing={true}
                    />
                </Box>


                {/*date picker*/}
                <Box marginBottom={2}>
                    <DatePicker
                        format="DD-MM-YYYY"
                        value={startDate ? dayjs(startDate) : null}
                        onChange={(dayjsDate) => setStartDate(dayjsDate?.toDate() || null)}
                    />

                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={2}>
                            {!timerStarted ? (
                                <Button onClick={handleStart} variant="contained" color="primary">
                                    Start
                                </Button>
                            ) : (
                                <div className={styles.ring}>
                                    <span></span>
                                </div>

                            )}
                            <Button onClick={handleStop} variant="contained" color="secondary" style={{ marginLeft: '10px' }}>
                                Stop
                            </Button>
                        </Box>
                    </Grid>

                    {/*For Desktop: 4 columns each. For Mobile: 12 columns (full width).*/}
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="minutes-input">Manual Entry (minutes)</InputLabel>
                            <Input
                                id="minutes-input"
                                type="number"
                                value={minutes}
                                onChange={(e) => {
                                    const newMinutes = Number(e.target.value);
                                    setMinutes(newMinutes);
                                    setTimer(newMinutes);
                                }}
                                inputProps={{
                                    min: 0,
                                    step: 1
                                }}
                            />

                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            label="Time Worked"
                            value={`${minutes}m`}
                            onChange={handleTimeChange}
                        />
                    </Grid>

                </Grid>


                    {/* Button */}
                <Grid item xs={12}>
                    <ThemeProvider theme={customTheme}>
                        <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            disabled={isSubmitDisabled}
                            style={{maxWidth: "100rem"}}
                        >
                            Submit
                        </Button>
                    </ThemeProvider>
                </Grid>

            </form>
        </div>
    );
};

export default NewTask;
