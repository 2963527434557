import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

let root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// const appRoot = ReactDOM.createRoot(root);
// appRoot.render(<App />);

// reportWebVitals();


root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
