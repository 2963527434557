import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button } from '@mui/material';
import BoldIcon from '@mui/icons-material/FormatBold';
import ItalicIcon from '@mui/icons-material/FormatItalic';
import LinkIcon from '@mui/icons-material/InsertLink';
import CodeIcon from '@mui/icons-material/Code';
import HeadingIcon from '@mui/icons-material/Title';
import ListIcon from '@mui/icons-material/FormatListBulleted';
import OrderedListIcon from '@mui/icons-material/FormatListNumbered';
import styles from '../../App.module.scss';
import { Select, MenuItem } from '@mui/material';

interface MarkdownEditorProps {
    markdown: string;
    onChange: (value: string) => void;
    isEditing: boolean;

}

function MarkdownEditor({ markdown, onChange, isEditing }: MarkdownEditorProps) {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const [rows, setRows] = useState<number>(5);  // Initial number of rows

    const calculateRows = (text: string) => {
        const numOfLines = text.split("\n").length;
        const additionalLines = Math.max(text.split('').filter(px => px === '\n').length, 0);
        setRows(numOfLines + additionalLines + 1);  // +1 for a bit of buffer
    }

    useEffect(() => {
        calculateRows(markdown);
    }, [markdown]);

    const applyMarkdown = (before: string, after: string) => {
        const textarea = textareaRef.current;
        if (!textarea) return;

        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const selectedText = markdown.substring(start, end);

        let newText = "";
        // Check if the markdown is for list types
        if (before === "- " || before.match(/^\d\.\s$/)) {
            const lines = selectedText.split('\n').map(line => `${before}${line}${after}`).join('\n');
            newText = `${markdown.substring(0, start)}${lines}${markdown.substring(end)}`;
        } else {
            newText = `${markdown.substring(0, start)}${before}${selectedText}${after}${markdown.substring(end)}`;
        }

        onChange(newText);

        // Refocus on the textarea and set the selection
        setTimeout(() => {
            if (textarea) {
                const newCursorPos = start + before.length + selectedText.length + after.length;
                textarea.focus();
                textarea.setSelectionRange(newCursorPos, newCursorPos);
            }
        }, 0);
    }

    console.log("MarkdownEditor rerendered with isEditing:", isEditing)

    return (
        <div>
            {isEditing && (
                <>
                    <div className={styles.editorButtons}>
                        <Button
                            variant="outlined"
                            onClick={(e) => {
                                e.preventDefault(); // prevent default button behavior
                                applyMarkdown("**", "**");
                            }}
                        >
                            <BoldIcon fontSize="small" />
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => applyMarkdown("*", "*")}
                        >
                            <ItalicIcon fontSize="small" />
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => applyMarkdown("[", "](url)")}
                        >
                            <LinkIcon fontSize="small" />
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => applyMarkdown("`", "`")}
                        >
                            <CodeIcon fontSize="small" />
                        </Button>
                        <Select
                            defaultValue="Paragraph"
                            onChange={(event) => {
                                switch (event.target.value) {
                                    case 'Heading 1':
                                        applyMarkdown("# ", "");
                                        break;
                                    case 'Heading 2':
                                        applyMarkdown("## ", "");
                                        break;
                                    case 'Heading 3':
                                        applyMarkdown("### ", "");
                                        break;
                                    default:
                                        break;
                                }
                            }}
                        >
                            <MenuItem value="Paragraph">Paragraph</MenuItem>
                            <MenuItem value="Heading 1">Heading 1</MenuItem>
                            <MenuItem value="Heading 2">Heading 2</MenuItem>
                            <MenuItem value="Heading 3">Heading 3</MenuItem>
                        </Select>

                        <Button
                            variant="outlined"
                            onClick={() => applyMarkdown("- ", "")}
                        >
                            <ListIcon fontSize="small" />
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => applyMarkdown("1. ", "")}
                        >
                            <OrderedListIcon fontSize="small" />
                        </Button>
                    </div>
                        <textarea
                        ref={textareaRef}
                    value={markdown}
                    onChange={(e) => {
                        onChange(e.target.value);
                        calculateRows(e.target.value);
                    }}
                    style={{
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#fff',
                        color: '#000',
                        fontFamily: 'Quicksand',
                        fontWeight: '400',
                        fontSize: '1em',
                        padding: '5px 5px 10px 5px'
                    }}
                    rows={rows}
                />
                </>
            )}
            {!isEditing && <ReactMarkdown>{markdown}</ReactMarkdown>}
        </div>


);
}

export default MarkdownEditor;
