import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AgencyList from "./agencies/AgencyList";
import ClientList from "./clients/ClientList";
import AllTasks from "./tasks/AllTasks";
import AllProjects from "./projects/AllProjects";
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // <-- Import here
import HomeIllustration from '../themes/graphics/homeGraphic';
import NewTask from "./tasks/NewTask";
import styles from '../App.module.scss';
const HomePage: React.FC = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null); // null for unknown, true for logged in, false for not logged in

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, user => {
            setIsLoggedIn(!!user); // sets true if user is logged in, else sets false
        });
        return () => unsubscribe(); // Clean up listener on unmount
    }, []);

    if (isLoggedIn === null) {
        // Content for users whose authentication status is still being determined
        return <div>Checking user status...</div>;
    }

    if (!isLoggedIn) {
        // Content for non-logged in users
        return (
            <>
                <br />
                <h1>Ralex Projects</h1>
                    <HomeIllustration />
                <p>Please <Link to="/login">Log in</Link> or <Link to="/register">Register</Link></p>
            </>
        );
    }

    // Content for logged in users
    return (
        <div>
            <h1>Welcome to Our App</h1>
            <h2><Link to="/agencies">Agencies</Link></h2>
            <AgencyList />
            <h2><Link to="/clients/">Clients</Link></h2>
            <ClientList agencyId="" /> {/* This will render the list of tasks */}
            <h2><Link to="/projects">Projects</Link></h2>
            <AllProjects />
            <h2><Link to="/tasks">Tasks</Link></h2>
            <AllTasks show={5} />
            <h3>Add a new task</h3>
            <NewTask taskStatus="billable"/>

        </div>
    );
};

export default HomePage;
