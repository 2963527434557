import React, { useState, useEffect, FC } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, where, onSnapshot, doc, getDoc, DocumentData, QueryDocumentSnapshot, Query, CollectionReference, QuerySnapshot, orderBy, limit } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { Task } from '../../types/types';
import { Button } from "@mui/material";
import styles from '../../App.module.scss';
import NewTask from './NewTask';

interface AllTasksProps {
    clientId?: string;
    show?: number;
}

const AllTasks: FC<AllTasksProps> = ({ clientId, show }) => {
    const [tasks, setTasks] = useState<Task[]>([]);
    const [clientNames, setClientNames] = useState<Record<string, string>>({});

    useEffect(() => {
        console.log('Fetching tasks from Firestore'); // Added log

        const tasksCol: CollectionReference<DocumentData> = collection(db, "tasks");
        let tasksQuery: Query<DocumentData> | CollectionReference<DocumentData> = tasksCol;

        try {
            if (clientId && typeof clientId === 'string' && clientId.trim() !== '') {
                tasksQuery = query(tasksCol, where("clientId", "==", clientId), orderBy('date', 'desc'), limit(show || 50));
            } else {
                tasksQuery = query(tasksCol, orderBy('date', 'desc'), limit(show || 50));
            }
        } catch (error) {
            console.error("Error setting up tasks query:", error); // Modified log for clarity
        }

        const unsubscribe = onSnapshot(tasksQuery, async (snapshot: QuerySnapshot<DocumentData>) => {
            const tasksList: Task[] = snapshot.docs.map((doc: QueryDocumentSnapshot<DocumentData>) => {
                const data = doc.data() as Task;
                return {
                    ...data,
                    id: doc.id
                }
            });

            console.log('Tasks from Firestore:', tasksList); // Added log

            const clientIds: string[] = [...new Set(tasksList.map(task => task.clientId).filter(Boolean))];
            const clientNamePromises = clientIds.map(async (id) => {
                const clientDoc = await getDoc(doc(db, 'clients', id));
                if (clientDoc.exists()) {
                    return { [id]: clientDoc.data()!.name };
                }
                return {};
            });
            const clientNameResults = await Promise.all(clientNamePromises);
            const clientNameMap = Object.assign({}, ...clientNameResults);

            console.log('Client names:', clientNameMap); // Added log

            setClientNames(clientNameMap);
            setTasks(tasksList);
        });

        return () => {
            unsubscribe();
        };
    }, [clientId, show]);

    return (
        <>
            <div className='card'>
                {tasks.length === 0 ? (
                    <p>There are no tasks yet</p>
                ) : (
                    tasks.map(task => (
                        <Link key={task.id} to={`/tasks/${task.id}`}>
                            <div className={styles.card}>
                                <p>{clientNames[task.clientId] || 'No Client'}</p>
                                <h3>{task.title}</h3>
                            </div>
                        </Link>
                    ))
                )}
            </div>
            <h3>Add a new task</h3>
            <NewTask taskStatus="billable"/>
        </>
    );
};

export default AllTasks;
