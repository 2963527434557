import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { User } from 'firebase/auth';  // Import the User type from Firebase
import styles from "../../App.module.scss";
const Login: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [user, setUser] = useState<User | null>(null);  // Specify the type for user state

    useEffect(() => {
        // Listen for auth state changes
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });

        // Cleanup listener on component unmount
        return () => unsubscribe();
    }, []);

    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            setSuccessMessage("Logged in successfully!");
            setErrorMessage(null);
        } catch (error) {
            setSuccessMessage(null);
            if (error instanceof Error) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage("An unknown error occurred.");
            }
        }
    };

    const handleLogout = async () => {
        try {
            await signOut(auth);
            setUser(null);  // Clear the user on logout
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };

    return (
        <div className={styles.loginWrapper}>
            {user ? (
                <Button
                    variant="contained"
                    color="secondary"
                    className={styles.logout}
                    onClick={handleLogout}
                >
                    Logout
                </Button>
            ) : (
                <>
                    <TextField
                        className={styles.loginInput}
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <br />
                    <TextField
                        className={styles.loginInput}
                        label="Password"
                        variant="outlined"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <br />
                    <Button variant="contained" color="primary" onClick={handleLogin}>
                        Login
                    </Button>
                </>
            )}

            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        </div>
    );
};

export default Login;
