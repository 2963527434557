import React, { useState, useEffect, FC } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, doc, getDoc, query, where } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { Project, Client, FetchedProject, RawFetchedProject } from '../../types/types';
import styles from '../../App.module.scss';

interface AllProjectsProps {
    clientId?: string;
}

const AllProjects: React.FC<AllProjectsProps> = ({ clientId }) => {
    const [projects, setProjects] = useState<FetchedProject[]>([]); // <-- Use FetchedProject here
    const [client, setClient] = useState<Client | null>(null);
    const [elapsedTimes, setElapsedTimes] = useState<Record<string, number>>({}); // Store elapsed times for each project

    useEffect(() => {
        const fetchProjects = async (): Promise<void> => {
            let projectsQuery;

            if (clientId) {
                // If clientId is provided, fetch only projects for that client
                projectsQuery = query(collection(db, "projects"), where("clientId", "==", clientId));
            } else {
                // If clientId is not provided, fetch all projects
                projectsQuery = collection(db, "projects");
            }

            const projectsSnapshot = await getDocs(projectsQuery);
            let projectsList: RawFetchedProject[] = projectsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data() as Project
            }));

            // Fetch the client's name and elapsed times for each project
            const projectsWithAdditionalData = await Promise.all(projectsList.map(async (project) => {
                const clientDocRef = doc(db, "clients", project.clientId);
                const clientSnapshot = await getDoc(clientDocRef);
                const clientData = clientSnapshot.data() as Client;

                // Fetch tasks associated with the project to calculate elapsed time
                const tasksQuery = query(collection(db, "tasks"), where("projectId", "==", project.id));
                const tasksSnapshot = await getDocs(tasksQuery);
                const allTasks = tasksSnapshot.docs.map(doc => doc.data());

                // Calculate total elapsed time for the project
                const totalElapsedTime = allTasks.reduce((total, task) => total + (task.elapsedTime || 0), 0);

                // Filter billable tasks and calculate their total elapsed time
                const billableTasks = allTasks.filter(task => task.taskStatus === "billable");
                const billableElapsedTime = billableTasks.reduce((total, task) => total + (task.elapsedTime || 0), 0);

                // Calculate project's total fee
                const projectFee = clientData?.hourlyRate ? clientData.hourlyRate * billableElapsedTime : 0;

                return {
                    ...project,
                    clientName: clientData?.name,
                    elapsedTime: totalElapsedTime,
                    fee: projectFee
                };
            }));

            // Updating state with projects and elapsedTimes
            setProjects(projectsWithAdditionalData);
            const updatedElapsedTimes = projectsWithAdditionalData.reduce((acc, project) => {
                acc[project.id] = project.elapsedTime;
                return acc;
            }, {} as Record<string, number>);
            setElapsedTimes(updatedElapsedTimes);
        };

        fetchProjects();
    }, [clientId]);

    return (
        <div className='card'>
            {projects.map(project => (
                <Link key={project.id} to={`/projects/${project.id}`}>
                    <div className={styles.card}>
                        {project.clientName && <p>{project.clientName}</p>}
                        <h3>{project.title}</h3>
                        <p>{project.elapsedTime || 0} hours spent</p>
                        <p>Total fee: ${project.fee.toFixed(2)}</p> {/* Display project total fee */}
                    </div>
                </Link>
            ))}
        </div>
    );

};

export default AllProjects;
