import React, { useState, useEffect } from 'react';
import { db } from '../../services/firebase';
import { collection, onSnapshot } from 'firebase/firestore';  // Import the onSnapshot function
import { Agency } from '../../types/types';
import { Link } from 'react-router-dom';
import styles from "../../App.module.scss";

const AgencyList: React.FC = () => {
    const [agencies, setAgencys] = useState<Agency[]>([]);

    useEffect(() => {
        const agenciesCol = collection(db, "agencies");

        // Use onSnapshot to listen for real-time updates
        const unsubscribe = onSnapshot(agenciesCol, (snapshot) => {
            console.log('Received agencies snapshot:', snapshot);

            const updatedAgencies: Agency[] = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data() as Agency
            }));

            console.log('Updated agencies:', updatedAgencies);
            setAgencys(updatedAgencies);
        }, (error) => {
            console.error('Error fetching agencies:', error);
        });

        // Cleanup the listener when the component is unmounted
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <div>
            {agencies.map(agency => (
                <Link to={`/agencies/${agency.id}`} key={agency.id}>
                    <div key={agency.id} className={styles.card}>
                        <h3>{agency.name}</h3>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default AgencyList;
