import React, { Suspense, lazy, useEffect } from 'react';
import {BrowserRouter, Link, Route, Routes} from 'react-router-dom';
import logo from './logo.svg';
import styles from './App.module.scss';
import { Button } from '@mui/material';
import NavMenu from "./components/layout/NavMenu";
import AllTasksa from './components/tasks/AllTasks';
import Login from './components/auth/Login';
import HomePage from './components/HomePage'; // Import the new component
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

library.add(faTrash);

const AgencyHome = lazy(() => import('./components/agencies/AgencyHome'));
const NewTask = lazy(() => import('./components/tasks/NewTask'));
const TaskView = lazy(() => import('./components/tasks/TaskView'));
const AllTasks = lazy(() => import('./components/tasks/AllTasks'));
// const AllProjects = lazy(() => import('./components/projects/AllProjects'));
const ProjectsHome = lazy(() => import('./components/projects/ProjectsHome'));
const NewProject = lazy(() => import('./components/projects/NewProject'));
const ProjectDetail = lazy(() => import('./components/projects/ProjectDetail'));
const AgencyView = lazy(() => import('./components/agencies/AgencyView'));
const ClientHome = lazy(() => import('./components/clients/ClientsHome'));
const NewClient = lazy(() => import('./components/clients/NewClient'));
const ClientView = lazy(() => import('./components/clients/ClientView'));
const NewAgency = lazy(() => import('./components/agencies/NewAgency'));
const Invoices = lazy(() => import('./components/invoice/invoices'));
const InvoiceView = lazy(() => import('./components/invoice/invoiceView'));


function App() {
    const chunkLoadErrorHandler = (event: Event & { error?: { name?: string } }) => {
        if (event.error && event.error.name === 'ChunkLoadError') {
            window.location.reload();
        }
    };


    useEffect(() => {
        window.addEventListener('error', chunkLoadErrorHandler);

        return () => {
            // Clean up event listener using the same function reference
            window.removeEventListener('error', chunkLoadErrorHandler);
        };
    }, []);



    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js').then(registration => {
                console.log('Service Worker registered with scope:', registration.scope);
            }).catch(err => {
                console.log('Service Worker registration failed:', err);
            });
        }
    }, []);



    return (
        <BrowserRouter>
            <ThemeProvider theme={createTheme()}>
                <header>
                    <NavMenu />
                    <br />
                </header>
                <div className={styles.AppBody}>
                    <div className={styles.App}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Routes>
                                <Route path="/" element={<HomePage />} />

                                {/* Agency */}
                                <Route path="/agencies" element={<AgencyHome />} />
                                <Route path="/new-agency" element={<NewAgency />} />
                                <Route path="/agencies/:agencyId" element={<AgencyView />} />

                                {/* Clients */}
                                <Route path="/clients" element={<ClientHome />} />
                                <Route path="/new-client" element={<NewClient />} />
                                <Route path="/clients/:clientId" element={<ClientView />} />

                                {/* Projects */}
                                <Route path="/projects" element={<ProjectsHome />} />
                                <Route path="/new-project" element={<NewProject clientId="null" />} />
                                <Route path="/projects/:projectId" element={<ProjectDetail />} />
                                <Route path="/clients/:clientId/projects/:projectId/" element={<ProjectDetail />} />

                                {/* Tasks */}
                                <Route path="/tasks" element={<AllTasks />} />
                                <Route path="/new-task" element={<NewTask taskStatus="billable" clientId="placeholder" />} />
                                <Route path="/tasks/:taskId" element={<TaskView />} />

                                {/* Invoices */}
                                <Route path="/invoices" element={<Invoices />} />
                                <Route path="/invoices/:invoiceId" element={<InvoiceView />} />
                            </Routes>

                        </Suspense>
                    </div>
                </div>
                <footer>
                    <Login />
                </footer>
                {/* Other content */}
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
