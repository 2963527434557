import { createTheme } from '@mui/material';

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#6200ea', // This is a deep purple color, you can choose any color you like.
        },
    },
    typography: {
        fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, \'Courier New\'',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '10px', // Rounded corners
                    padding: '10px 20px', // Padding around the text
                    fontSize: '16px', // Adjust the font size if needed
                    textTransform: 'none', // Make text appear as written (not uppercase)
                    boxShadow: '0 3px 5px 2px #E2E2E2FF', // Shadow for the button to give it a lifted appearance
                },
                contained: {
                    backgroundColor: '#313030',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#646363', // A shade darker for hover state
                    }
                }
            }
        }
    }
});


export default customTheme;
